/**
 * Styles for figure atom
 */

figure {
  @apply
    mb-0
    inline-block
    relative;
}

figcaption {
  @apply
    text-sm;
}

.figure--block {
  @apply block;
}

.figure__bg {
  z-index: -1;
  width: 85%;
  height: 85%;
  margin: -20px;

  @screen md {
    margin: -8% -45%;
  }
}

.figure__bg__caption {
  @apply text-sm;

  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
  margin: 0 0 -20% 0;

  @screen md {
    z-index: 30;
    display: block;
    width: 100%;
    margin: 0 -60% 42% 0;
    margin: 0 -60% 40% 0;
    transform: rotate(90deg);
    transform-origin: 50% 50%;
  }

  @screen lg {
    margin: 0 -57% 45% 0;
  }

  @screen xl {
    margin: 0 -57% 40% 0;
  }
}
