/* stylelint-disable no-descending-specificity */

.btn {
  @apply bg-transparent
    border
    font-bold
    inline-block
    leading-none
    no-underline
    px-4
    py-3
    rounded-lg
    shadow-btn
    text-brand-blue-light;

  /* Adjusted bottom padding for visual centred text */

  /* padding-bottom: 0.85rem; */
  transition: all 300ms;

  &:hover,
  &:focus {
    @apply border-accent-blue
      no-underline
      text-brand-blue
      shadow-none;
  }
}

/* Primary Variant */
.btn--accent-blue {
  @apply antialiased
    border-accent-blue
    bg-accent-blue
    shadow-btn__primary
    text-white;

  .pretty & {
    @apply text-white;
  }

  &:hover,
  &:focus {
    @apply text-white;

    border: 1px solid #1d5fa3;
    background-color: #1d5fa3;

    & .btn__icon {
      @apply text-white;
    }
  }
}

.btn--accent-yellow {
  @apply antialiased
    text-black
    border-brand-yellow-2
    bg-brand-yellow-2;

  &:hover,
  &:focus {
    @apply text-black;

    border: 1px solid #d6aa12;
    background-color: #d6aa12;
  }
}

.btn--accent-white {
  @apply antialiased
    border-white
    bg-white;

  color: #0a3b85 !important;

  &:hover,
  &:focus {
    border: 1px solid #d7d7d7;
    background-color: #d7d7d7;
  }
}

.btn--accent-black {
  @apply antialiased
    text-black
    border-black
    bg-transparent;

  &:hover,
  &:focus {
    @apply border-black
      text-black
      bg-transparent;
  }
}

.btn--bordered-blue {
  @apply bg-transparent
    shadow-none
    text-blue-zodiac;

  border: 2px solid rgba(20, 27, 77, 1);

  &:hover,
  &:focus {
    @apply text-brand-blue
      border-accent-blue-dark;

    background-color: rgba(65, 143, 222, 0.2);
  }
}

.btn--bordered-blue-alt {
  @apply bg-transparent
    shadow-none
    text-catalina-blue;

  border: 1px solid #09397f;

  &:hover,
  &:focus {
    background-color: rgba(65, 143, 222, 0.2);
  }
}

/* Naked Variant */
.btn--naked {
  @apply bg-transparent
    border-transparent
    shadow-none
    text-inherit;

  &:hover,
  &:focus {
    @apply border-white
      text-white;
  }
}

.btn--naked--bordered {
  @apply bg-transparent
    border-white
    shadow-none
    text-inherit;

  &:hover,
  &:focus {
    @apply border-grey-mid-2
      text-white;
  }
}

/* Disabled Button Styles */
.btn,
button {
  &:disabled {
    @apply opacity-25;
  }

  &:focus {
    outline: 1px dotted;
  }
}
