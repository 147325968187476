.rising-stars {
  .highlight {
    color: #fd0 !important;
  }

  .text-dark {
    color: #333 !important;
  }

  .bg-blue {
    background-color: #163f90 !important;
  }

  .bg-orange {
    background-color: #ed8b01 !important;
  }

  .text-blue {
    color: #163f90 !important;
  }

  .text-hr {
    color: #009bae !important;
  }

  .bg-hr {
    background-color: #009bae !important;
  }

  .text-lm {
    color: #6c2477 !important;
  }

  .bg-lm {
    background-color: #6c2477 !important;
  }

  .text-emp {
    color: #ed8b01 !important;
  }

  .bg-emp {
    background-color: #ed8b01 !important;
  }

  .primary-section {
    @apply text-white;

    background-color: #163f90;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      color: #fff;
    }
  }

  .secondary-section {
    @apply bg-white;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      color: #163f90;
    }
  }

  .tertiary-section {
    @apply bg-white;

    color: #163f90;
  }

  .narrow-block {
    @apply mx-auto;

    max-width: 860px;


    &.bg-white {
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: #163f90;
      }

      p {
        color: #333;
      }
    }

    &.bg-blue {
      h1,
      h2,
      h3,
      h4,
      h5,
      p {
        color: #fff;
      }
    }
  }
}
