/* stylelint-disable */

/**
 * Tailwind
 */
@import "tailwindcss/base.css";
@import "tailwindcss/components.css";

/**
 * Atoms
 */
@import "@/components/atoms/blockquote/_blockquote.postcss";
@import "@/components/atoms/btn-group/_btn-group.postcss";
@import "@/components/atoms/btn/_btn.postcss";
@import "@/components/atoms/container/_container.postcss";
@import "@/components/atoms/dot-grid/_dot-grid.postcss";
@import "@/components/atoms/figure/_figure.postcss";
@import "@/components/atoms/headings/_headings.postcss";
@import "@/components/atoms/link/_link.postcss";
@import "@/components/atoms/list/_list.postcss";
@import "@/components/atoms/meta/_meta.postcss";
@import "@/components/atoms/paragraph/_paragraph.postcss";
@import "@/components/atoms/responsive-embed/_responsive-embed.postcss";
@import "@/components/atoms/status-indicator/_status-indicator.postcss";
@import "@/components/atoms/table/_table.postcss";
@import "@/components/atoms/iframe/_iframe.postcss";
@import "@/components/atoms/feedback-button/_feedback-button.postcss";

/**
 * Molecules
 *
 * @Important! Every molecule should be prefaced with .m-,
 * e.g: .m-awesome-component.
 *
 */
@import "@/components/molecules/testimonial-carousel/_carousel.postcss";
@import "@/components/molecules/hero/_hero.postcss";
@import "@/components/molecules/media/_media.postcss";
@import "@/components/molecules/media/hero-videos/_hero-videos.postcss";
@import "@/components/molecules/navigation/neighbourhood-nav/_neighbourhood-nav.postcss";
@import "@/components/molecules/navigation/pane-item/_pane-item.postcss";
@import "@/components/molecules/notification/_notification.postcss";

/**
 * Forms
 */
@import "@/components/forms/checkbox/_checkbox.postcss";
@import "@/components/forms/input/_input.postcss";
@import "@/components/forms/label/_label.postcss";
@import "@/components/forms/radio/_radio.postcss";
@import "@/components/forms/select/_select.postcss";
@import "@/components/forms/_formie.postcss";

/**
 * Single page styles
 */
@import "@/components/singles/_rising-stars.postcss";
@import "@/components/singles/_course-new.postcss";

/**
 * Vendors
 */

/* purgecss start ignore */
@import "@splidejs/splide/dist/css/splide-core.min.css";

/* purgecss end ignore */

/*
	Utilities last so they override
 */
@import "@/components/utilities/_trim.postcss";
@import "@/components/utilities/pretty/_pretty.postcss";
@import "tailwindcss/utilities.css";

html {
  @apply bg-masthead-blue
    text-white;
}

body {
  @apply bg-white
    text-black;

  min-width: 320px;
  font-family: "effra", "sans-serif";
  overflow-x: hidden;

  * {
    scroll-margin-top: 80px;
  }
}

@font-face {
  font-family: "Graphik";
  src: url("../fonts/GraphikRegular.otf") format("opentype");
}

@font-face {
  font-family: "Graphik Bold";
  src: url("../fonts/GraphikMedium.otf") format("opentype");
}

header {
  font-family: "Graphik", "sans-serif";
  font-style: normal;
  font-weight: 500;

  h4,
  h6,
  .rightNavigation,
  .searchButton {
    font-family: "Graphik Bold", "sans-serif";
  }
}

.desktopTabs {
  font-family: "Graphik Bold", "sans-serif";
}

.searchOverlay {
  font-family: "Graphik";
}

button {
  font-size: inherit;
}

#icon-default-arrow path {
  stroke: #333 !important;
}

#icon-default-hover-arrow path {
  stroke: #0a3b85 !important;
}

svg #icon-default-arrow path {
  stroke: #333 !important;
}

svg #icon-default-hover-arrow path {
  stroke: #0a3b85 !important;
}

/* @todo move this */
.js\:hidden {
  .js-loading &,
  .js-loaded & {
    display: none;
  }
}

/* $TODO - Move this into a component! */
.has--ribbon {
  @apply relative;

  &::before {
    @apply bg-brand-yellow h-8 top-0;

    @screen md {
      @apply h-16;
    }
  }

  &::after {
    @apply h-4;

    top: 1em;
    border-top: 0.5em solid transparent;
    border-right: 0.5em solid transparent;
    border-bottom: 0.5em solid #0a3b85;
    border-left: 0.5em solid transparent;

    @screen md {
      top: 2em;
      border-width: 1em;
    }
  }

  &::before,
  &::after {
    @apply absolute block w-4;

    left: 1em;
    content: "";

    @screen md {
      @apply w-8;
    }
  }
}

.page-content a:not(.btn):not(.no-underline) {
  @apply underline;
}

.people-image-sizing-fix {
  contain: size !important;
  contain-intrinsic-size: 300px;
}

.personal-profile-image {
  max-width: 120px;

  @screen md {
    max-width: 170px;
  }

  img {
    border-radius: 10px;
  }
}
