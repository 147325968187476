/* Hide the default radio */
.radio input[type="radio"] {
  @apply
    absolute
    opacity-0
    -z-1;
}

.radio {
  display: flex;

  label {
    @apply
      flex
      justify-center
      items-center
      pl-8
      relative
      text-comet;
  }

  + .radio {
    @apply .mt-2;
  }
}

.radio label::before,
.radio label::after {
  @apply
    absolute
    bg-white
    left-0
    inline-block
    transition;

  content: "";
}

/* Outer box of the fake radio */
.radio label::before {
  @apply
    border
    border-grey-light
    rounded rounded-full
    w-6 h-6;
}

/* dot of the fake radio */
.radio label::after {
  @apply
    rounded
    rounded-full
    w-2 h-2;

  left: 8px;
}

/* Hide the checkmark by default */
.radio input[type="radio"] + label::after {
  content: none;
}

/* Unhide on the checked state */
.radio input[type="radio"]:checked + label::after {
  content: "";
}

/* Background color added to the box when checked */
.radio input[type="radio"]:checked + label::before {
  @apply bg-accent-blue border-accent-blue;
}

/* Disabled state */
.radio input[type="radio"]:disabled + label::before {
  @apply bg-grey border-grey;
}

.radio input[type="radio"]:disabled + label {
  @apply text-grey;
}

/* Adding focus styles on the outer-box of the fake radio */
.radio input[type="radio"]:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}
