/*
  Carousel
 */

.m-carousel {
  @apply relative;
}

.m-carousel__item {
  @apply hidden w-full transition;
}

.m-carousel__item.active {
  display: block;
}
