.feedback-button {
  @apply bg-blue-zodiac border border-blue-zodiac;

  position: fixed;

  /* Changed from 99 to 29 so it is hidden when mobile menu is open */
  z-index: 29;
  right: 15px;
  bottom: 40%;
  display: block;
  overflow: hidden;
  width: 52px;
  height: 52px;
  padding: 3px;
  margin-bottom: 80px;
  border-radius: 28px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-out;

  &:hover,
  &:focus,
  &:active {
    @apply bg-brand-blue;

    width: 255px;
  }

  &:active {
    margin-bottom: 79px;
  }

  svg {
    position: relative;
    left: 1px;
    width: 32px;
    height: 32px;
    margin: 6px;
    filter: FlipH;
    transform: scaleX(-1);
  }

  span {
    position: absolute;
    top: 4px;
    left: 55px;
    display: block;
    min-width: 190px;
  }
}
