/* Set / reset some base form styles at the highest level */
input {
  color: currentColor;
  font: inherit;

  &:disabled {
    @apply
      cursor-not-allowed
      opacity-50;
  }

  &::placeholder {
    color: currentColor;
  }
}

/* Make texty-type inputs look like text inputs */
.input {
  @apply
    bg-white
    border border-lavender
    outline-none
    py-3 px-4
    w-full
    transition;

  &:active,
  &:focus {
    @apply border-accent-blue;
  }
}

/* Make buttony-type inputs look like buttons
input[type="button"],
input[type="submit"],
input[type="reset"] {
  @apply .btn;
}
*/
