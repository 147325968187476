/**
 * Styles for meta atom
 */

.meta {
  @apply
    bg-white
    border
    border-blue-cadet
    font-bold
    inline-block
    leading-normal
    no-underline
    p-2
    rounded-sm
    text-blue-zodiac
    text-xs
    transition;

  button&,
  a& {
    &:hover,
    &:focus {
      @apply bg-grey-light no-underline transition;
    }
  }

  + .meta {
    @apply mb-1 ml-1;
  }
}

.meta--accent-blue {
  button&,
  a& {
    @apply antialiased bg-status-blue border-white text-white;

    &:hover,
    &:focus {
      @apply bg-accent-blue-dark no-underline;
    }
  }
}

/*
.meta--white {
  @apply bg-white text-brand-blue;

  button&,
  a& {
    &:hover,
    &:focus {
      @apply bg-white text-accent-blue no-underline;
    }
  }
}

.meta--grey {
  @apply bg-grey-dark text-white;

  button&,
  a& {
    &:hover,
    &:focus {
      @apply bg-grey text-white no-underline;
    }
  }
}

.meta--white-border {
  @apply bg-white text-brand-blue border border-brand-blue;

  button&,
  a& {
    &:hover,
    &:focus {
      @apply bg-white text-accent-blue border border-accent-blue;
    }
  }
}

.meta--status,
.meta--status-in-progress {
  @apply bg-accent-blue;

  & > .meta__inner {
    @apply flex flex-wrap items-center;
  }

  & .status-indicator {
    @apply mr-2;
  }

  &:hover,
  &:focus {
    @apply bg-accent-blue-dark;
  }
}
*/
