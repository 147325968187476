/**
 * Styles for link atom
 */

a,
.link {
  @apply no-underline
    text-inherit
    transition;

  /* Create better-looking links in browsers that support them */
  text-decoration-skip-ink: auto;

  &:hover,
  &:focus {
    @apply underline
      text-currentColor
      transition;
  }
}
