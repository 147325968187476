/* stylelint-disable no-descending-specificity */

.m-pane-item {
  @apply
    list-none
    text-white text-sm
    p-0 m-0
    flex flex-wrap
    bg-brand-blue;

  &:hover,
  &:focus-within {
    @apply bg-brand-blue-dark;
  }
}

.m-pane-item--indented {
  @apply pl-4;
}

.m-pane-item--back:hover {
  @apply bg-accent-blue-dark;
}

.m-pane-item--separator-before {
  @apply mt-8;

  &::before {
    content: "";

    @apply block
      mx-8
      -mt-4
      w-full
      border-t
      border-brand-blue-light;

    .w-contracted & {
      @apply mx-4;
    }
  }
}


.m-pane-contractor {
  @apply w-8;

  &:hover,
  &:focus-within {
    @apply bg-blue-zodiac-dark;
  }
}

.m-pane-item__link {
  @apply
    py-3
    border-l-6 border-transparent
    flex-1 flex flex-wrap items-center
    no-underline
    text-left;

  transition: none;
}

.m-pane-item__expander {
  @apply w-16;
}


.m-pane-parent {
  @apply relative;

  padding-right: 120px;

  &:hover,
  &:focus {
    @apply bg-accent-blue;

    .m-pane-parent__button {
      @apply bg-accent-blue-dark;
    }
  }
}

.m-pane-parent__button {
  @apply
    bg-accent-blue
    rounded-sm
    border border-solid border-white
    font-normal
    text-sm;

  padding: 2px 6px;
  margin-top: 10px;
}

.m-pane-parent__button--fixed {
  @apply
    absolute;

  top: 50%;
  right: 37px;
  margin-top: -13px;
}

.m-pane-item__external {
  @apply
    text-white
    inline-block
    w-12
    text-right
    ml-auto;
}
