.fui-form {
  @apply bg-grey-lighter py-8 px-8;

  .fui-instructions {
    p {
      color: #333 !important;
    }
  }

  .fui-field-container {
    @apply mb-4;

    label {
      font-size: 1rem;
    }
  }

  .fui-btn-container {
    @apply flex justify-end;
  }
}

@media (max-width: 1023px) {
  .fui-form {
    @apply py-6 px-4;
  }
}
