/**
  Iframe atom styles
 */
.iframe {
  border: none;
}

.iframe--block {
  display: block;
}

.iframe--full {
  display: block;
  width: 100%;
  height: calc(100vh - 64px);
  min-height: 72vh;
  margin-right: 100px;
}

.iframe--wrike {
  width: calc(100% + 48px);
  height: 1300px;
  min-height: 72vh;

  @screen md {
    height: 880px;
  }
}

.iframe--adx {
  @screen md {
    height: 920px;
  }
}

.iframe--infographic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iframe__infographic-container {
  position: relative;
  width: 100%;
  padding-top: 100%;
}
