.status-indicator {
  @apply inline-block rounded rounded-full w-2 h-2 bg-accent-blue;

  animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 rgba(42, 117, 235, 0.4);
}

.status-indicator--brand-yellow {
  @apply bg-brand-yellow;

  animation: pulse-yellow 1.5s infinite;
}

.status-indicator--white {
  @apply bg-white;

  animation: pulse-white 1.5s infinite;
}

.status-indicator--lg {
  @apply w-4 h-4;
}


@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(42, 117, 235, 0.4);
  }

  70% {
    box-shadow: 0 0 0 8px rgba(42, 117, 235, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(42, 117, 235, 0);
  }
}

@keyframes pulse-yellow {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 206, 0, 0.4);
  }

  70% {
    box-shadow: 0 0 0 8px rgba(255, 206, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 206, 0, 0);
  }
}

@keyframes pulse-white {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  70% {
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
