.m-media {
  @apply
    relative
    pr-6 pb-6;

  @screen md {
    @apply pr-12 pb-12;
  }
}

/*
.m-media__dot-grid {
  @apply
    absolute
    block
    overflow-hidden
    top-0 right-0 z-0
    w-1/3 h-full
    pb-12;
}
*/
