.course-content {
  @media (max-width: 1023px) {
    order: 2;

    * {
      scroll-margin-top: 128px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 3.5rem;
  }

  &__section {
    &-header {
      margin-bottom: 3.5rem;
    }

    &-spacer {
      width: 6.25rem;
      height: 0.25rem;
      margin-top: 2rem;
    }
  }

  .wysiwyg-output {
    &:last-child {
      *:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.course-sidebar {
  z-index: 27;

  @media (max-width: 1023px) {
    position: sticky;
    top: 80px;
    order: 1;
  }

  &__container {
    position: sticky;
    top: 80px;

    @media (min-width: 1024px) {
      max-width: 286px;
      margin-left: auto;
    }
  }

  .btn {
    width: 100%;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    margin-bottom: 0.5rem;
    box-shadow: none;

    > div {
      justify-content: space-between;
    }

    &.btn--accent-yellow {
      color: #141b4d;
    }
  }
}

.course-page-navigation {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  gap: 1px;

  @media (max-width: 1023px) {
    display: none;
  }

  &__link,
  a {
    color: #141b4d;
    text-decoration: none;
  }

  &__link {
    position: relative;
    display: block;
    padding: 0.75rem 1rem;

    &.active,
    &:active,
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #edeff7;
    }

    &:hover {
      text-decoration: underline;
      transition: all 300ms;
    }

    &:active {
      color: #09397f;
      font-weight: 700;
    }

    &.active {
      color: #09397f;
      font-weight: 700;

      &:active {
        background: #edeff7;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background: #09397f;
        content: "";
      }
    }

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      &::before {
        border-top-left-radius: 8px;
      }
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      &::before {
        border-bottom-left-radius: 8px;
      }
    }
  }

  &-dropdown {
    position: relative;
    border-radius: 8px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background: #09397f;
      content: "";
    }

    &__select {
      width: 100%;
      padding: 0.75rem 1rem;
      appearance: none;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }
}

.course-tabs {
  &__header {
    display: flex;
    flex-wrap: wrap;
  }

  &__tab {
    position: relative;
    padding: 1rem 1.25rem;
    cursor: pointer;

    &:hover {
      background: #edeff7;
      text-decoration: underline;
      transition: all 300ms;
    }

    &:first-child {
      border-top-left-radius: 0.5rem;

      &::before {
        border-top-left-radius: 0.5rem;
      }
    }

    &:last-child {
      border-top-right-radius: 0.5rem;

      &::before {
        border-top-right-radius: 0.5rem;
      }
    }

    &.active {
      background: #edeff7;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: #09397f;
        content: "";
      }
    }
  }

  &__content {
    display: none;
    padding: 2rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    &.active {
      display: block;
    }
  }
}

.course-call-to-action {
  @media (max-width: 1023px) {
    display: none;
  }
}

.course-marketing-claims {
  &:last-child {
    margin-bottom: 0;
  }
}

.course-video {
  .m-hero-video,
  iframe {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  button {
    border-top-right-radius: 0.5rem;
  }
}

.course-external-organisations {
  &:last-child {
    margin-bottom: 0;

    .m-teaser-right .border-b {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  button:focus {
    outline: none;
  }
}

.course-module {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    gap: 0.65rem;

    @media (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title {
    flex: 1;
    margin: 0;
  }

  &__content p:last-child {
    margin-bottom: 0;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem 0;
    margin-top: 1rem;
    gap: 0.65rem;

    @media (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-item {
      width: 100%;
      padding-bottom: 2rem;

      &:not(:last-child) {
        border-bottom: 1px solid #d6dced;
        margin-bottom: 2rem;
      }
    }
  }

  &__author-list {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    gap: 1.5rem;
  }

  &__cta {
    @media (max-width: 480px) {
      margin-top: 1rem;
    }

    .btn {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}

.author-card {
  display: flex;
  align-items: center;
  gap: 0.65rem;
}

.course-testimonial {
  display: flex;
  padding: 2rem;
  margin-top: 3.5rem;
  gap: 2rem;

  &:not(:last-child) {
    margin-bottom: 3.5rem;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }

  &__image {
    max-width: 147px;
  }

  &__quote {
    margin-bottom: 2rem;
    line-height: 130%;
  }

  &__courses {
    margin-top: 0.5rem;
  }

  &__content {
    flex: 1;

    .btn {
      margin-top: 1rem;
    }
  }
}

.course-testimonial__image + .course-testimonial__content {
  padding-left: 2rem;
  border-left: 1px solid #d6dced;

  @media (max-width: 600px) {
    padding-top: 2rem;
    padding-left: 0;
    border-top: 1px solid #d6dced;
    border-left: none;
  }
}

.course-related {
  &__container {
    gap: 2rem;
  }
}

.course-testimonial-carousel button:focus {
  outline: none;
}

.designstudio-button {
  bottom: 5rem !important;

  .course-hero-sticky & {
    top: 23rem;
    bottom: auto !important;

    @media (max-width: 480px) {
      top: 14.25rem;
    }
  }
}
