blockquote,
.blockquote {
  @apply
    trim
    mb-3;

  p {
    @apply
      mb-2;

    &::before {
      content: "“";
    }

    &:last-child {
      &::after {
        content: "”";
      }
    }
  }
}

cite {
  @apply
    font-bold
    not-italic;
}
