.responsive-embed {
  padding-bottom: 56.25%;

  @apply
    relative
    h-0
    overflow-hidden
    max-w-full;


  iframe,
  object,
  embed {
    @apply
      absolute
      top-0
      left-0
      w-full
      h-full;
  }
}
