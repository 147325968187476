/*
  Notification molecule
 */
.m-notification {
  @apply bg-accent-blue
    text-white
    text-sm
    py-4
    px-5
    mb-5;
}

.m-notification__text {
  @apply trim;
}

.m-notification--white {
  @apply bg-white
    text-black;
}
