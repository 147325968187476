
.table-styled {
  @apply border-collapse table-auto w-full;

  th,
  td {
    @apply border border-grey-light p-3;
  }

  thead tr {
    & th,
    td {
      @apply border-b-2 border-grey-light text-sm;
    }
  }


  tr:nth-child(odd) {
    & th,
    td {
      @apply bg-grey-lighter;
    }
  }

  thead tr:nth-child(odd) {
    & th,
    td {
      @apply bg-white;
    }
  }
}
