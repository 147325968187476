.m-neighbourhood-nav {
  @apply border border-grey-light;
}

.m-neighbourhood-nav__docked-heading {
  @apply
    bg-grey-light
    block
    w-full
    px-6
    py-1
    text-sm;
}

.m-neighbourhood-nav__heading {
  @apply
    flex flex-wrap
    items-center
    w-full
    px-6 py-4
    border-b
    border-grey-light
    no-underline;

  &:hover,
  &:focus {
    @apply text-black bg-grey-lighter;
  }
}

.m-neighbourhood-nav__item {
  @apply
    px-6 py-4
    block
    border-grey-light
    no-underline;
}

a.m-neighbourhood-nav__item {
  @apply text-accent-blue;

  &:focus,
  &:hover {
    @apply
      text-brand-blue text-brand-blue
      bg-grey-lighter bg-grey-lighter;
  }
}

.m-neighbourhood-nav--child {
  @apply
    list-disc list-inside
    border-b border-grey-light
    no-underline;
}

.m-neighbourhood-nav__item--child {
  @apply
    text-accent-blue
    p-4
    pl-5;

  &:hover,
  &:focus {
    @apply text-brand-blue text-brand-blue;
  }
}
