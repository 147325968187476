.container {
  @apply mx-auto
    transition
    px-8;

  max-width: theme("screens.xl");
}

@media only screen and (max-width: theme(screens.md)) {
  .container--no-pad {
    @apply px-0;
  }
}

/* @TODO @Shame move this Sam */
.w-minusExpanded {
  width: calc(theme("maxWidth.full") - theme("maxWidth.expanded"));
}

.w-minusContracted {
  width: calc(theme("maxWidth.full") - theme("maxWidth.contracted"));
}

.w-contracted-hide-scroll {
  width: calc(theme("maxWidth.contracted") + 1.2rem);
}

.w-expanded-hide-scroll {
  width: calc(theme("maxWidth.expanded") + 1.2rem);
}
