
.checkbox {
  @apply block
    mt-4
    mb-4;

  &:hover {
    @apply cursor-pointer;
  }

  /* Hide the real checkbox */
  & input {
    @apply
      absolute
      -z-1;

    /* Required to overide base diabled style */
    opacity: 0 !important;
  }

  /* Create a faux checkbox */
  & .checkbox__label {
    @apply
      flex
      items-center
      pl-8
      relative;

    top: 2px;

    &::before {
      @apply
        absolute
        bg-white
        border border-lavender
        block
        flex items-center justify-center
        h-6 w-6
        left-0 top-0
        text-white
        transition;

      content: "";
    }
  }

  /* Update the faux checkbox styles on checked state */
  & input:checked + .checkbox__label::before {
    @apply
      bg-accent-blue
      border-accent-blue;

    /* Use the right html entity for the job */
    content: "\2713";
  }

  /* Apply disabled checkbox styles to the faux checkbox */
  & input:disabled + .checkbox__label::before {
    @apply
      cursor-not-allowed
      opacity-50;
  }
}
