.select {
  @apply
    w-full
    bg-white
    border border-lavender
    py-3
    px-4
    block
    font-sans;

  appearance: none;
  border-radius: 0;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    @apply border-accent-blue;
  }
}

.select__wrapper {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  vertical-align: middle;
}

.select__icon {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  left: auto;
  pointer-events: none;
}
