h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  @apply font-medium
    leading-tight
    mb-4
    text-blue-zodiac
    tracking-slight;
}

h1,
.h1 {
  @apply font-light
    text-2xl;

  @screen md {
    @apply text-3xl
      leading-micro;
  }
}

h2,
.h2 {
  @apply text-xl;

  @screen md {
    @apply text-2xl;
  }
}

h3,
.h3,
.wysiwyg-output h1,
.wysiwyg-output h2 {
  @apply text-lg;

  @screen md {
    @apply text-xl;
  }
}

h4,
.h4 {
  @apply text-md;

  @screen md {
    @apply text-lg;
  }
}

h5,
.h5 {
  @apply text-base;

  @screen md {
    @apply text-md;
  }
}

h6,
.h6 {
  @apply text-base;
}

.h-reset {
  font-size: inherit;

  @screen md {
    font-size: inherit;
  }

  @screen xl {
    font-size: inherit;
  }
}

.h-trim {
  @apply mb-0 pb-0;
}
