/**
 * Styles for list atom
 */


.list {
  @apply mb-5
    pl-5;

  li {
    @apply mb-2;
  }

  ul& {
    @apply list-disc;
  }

  ol& {
    @apply list-decimal;
  }

  .checked& {
    @apply list-none p-0;

    & li {
      @apply
        flex
        items-center
        leading-relaxed
        p-0
        mb-4
        pl-8
        relative
        text-md;

      &::before {
        @apply
          absolute
          bg-brand-yellow
          block
          h-1
          left-0
          mr-2
          w-4;

        top: 0.75rem;
        content: "";
      }
    }
  }
}
