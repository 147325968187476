/**
 * Styles for paragraph atom
 */

p {
  @apply
    leading-normal
    mb-5;
}

.lede {
  @apply text-lg;
}

.micro {
  @apply text-sm;
}

.no-margin p {
  @apply mb-0;
}

.lede-first p:first-child {
  @apply text-lg;
}
